<template>
  <div class="flex h-full flex-col justify-between">
    <div>
      <h2>{{ $t('import_patients.csv_upload_form.title') }}</h2>
      <div class="mt-12 flex items-center justify-start">
        <div class="mr-4 flex h-10 w-10 items-center justify-center">
          <hero-icon name="AlertOutlinetIonIcon" size="default" classes="text-textSecondary"></hero-icon>
        </div>
        <span class="shrink grow text-xs text-textSecondary">
          {{
            $t('import_patients.csv_upload_form.already_sollicited_info', {
              number_of_days_for_reimport: currentClinic?.number_of_days_for_reimport || defaultNoOfDaysForReImport,
            })
          }}
          <a class="text-xs" :href="`mailto:${contact}`">{{ contact }}</a
          >.
        </span>
      </div>
      <form class="mt-12 text-left" @submit.prevent>
        <div
          v-if="showError"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-danger bg-red-100 p-4 text-sm text-danger"
          @click="handleCloseAlert"
        >
          <span class="mr-2 text-red-dark">{{ errorMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <div
          v-if="showSuccess"
          class="mb-5 flex w-full items-center justify-between border-l-2 border-green bg-green-100 p-4 text-sm text-success"
          @click="showSuccess = false"
        >
          <span class="mr-2 text-green-dark">{{ successMessage }}</span>
          <hero-icon name="CloseHeroIcon" classes="hover:cursor-pointer" size="small"></hero-icon>
        </div>
        <div class="mb-6">
          <div v-if="store.sendEsatisEmailing === 'true' && store.sendingOption === 'email'" class="mb-2 lg:flex lg:justify-between">
            <label class="relative my-2 mb-3 inline-flex cursor-pointer items-center">
              <span class="text-sm font-medium text-gray-900 dark:text-gray-300">{{
                $t('import_patients.csv_upload_form.where_find_exit_date')
              }}</span>
            </label>
            <div class="flex items-center justify-end lg:ml-4">
              <label for="number-input" class="mr-2 block text-sm font-medium text-textSecondary dark:text-white">{{
                $t('import_patients.csv_upload_form.column_number')
              }}</label>
              <input
                id="number-input"
                v-model="v$.exitDateColumn.$model"
                type="number"
                aria-describedby="helper-text-explanation"
                class="block w-16 rounded-lg border border-gray-300 bg-gray-50 px-2.5 py-1 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                required
                default="6"
              />
            </div>
          </div>
          <p v-if="v$.exitDateColumn.$error" class="mb-2 ml-4 text-xs text-danger md:ml-0 md:text-right">
            {{ $t('import_patients.csv_upload_form.column_number_error') }}
          </p>
          <div class="mb-2 lg:flex lg:items-center lg:justify-between">
            <label class="relative my-2 mb-3 inline-flex cursor-pointer items-center">
              <span class="text-sm font-medium text-gray-900 dark:text-gray-300">{{ $t('import_patients.csv_upload_form.use_tag_column') }}</span>
              <div data-tooltip-target="tag-tooltip" data-tooltip-placement="bottom" class="h-5 w-5">
                <hero-icon name="InformationOutlineIonIcon" classes="ml-2 text-textSecondary hover:text-primary" size="default"></hero-icon>
              </div>
              <default-tooltip
                id="tag-tooltip"
                classes="bg-blue-lighter text-blue-500 text-xs"
                :message="$t('import_patients.csv_upload_form.tag_tooltip')"
              />
              <div class="relative ml-3">
                <input v-model="useTagColumn" type="checkbox" value="" class="peer sr-only" />
                <div
                  class="peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
                ></div>
              </div>
            </label>
            <div v-if="showColumnSelection" class="flex items-center justify-end lg:ml-4">
              <label for="number-input" class="mr-2 block text-sm font-medium text-textSecondary dark:text-white">{{
                $t('import_patients.csv_upload_form.column_number')
              }}</label>
              <input
                id="number-input"
                v-model="v$.tagColumn.$model"
                type="number"
                aria-describedby="helper-text-explanation"
                class="block w-16 rounded-lg border border-gray-300 bg-gray-50 px-2.5 py-1 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                required
              />
            </div>
          </div>
          <p v-if="v$.tagColumn.$error" class="mb-2 ml-4 text-xs text-danger md:ml-0 md:text-right">
            {{ $t('import_patients.csv_upload_form.column_number_error') }}
          </p>
          <div class="my-6 flex w-full items-center justify-center" @dragover.prevent @dragenter.prevent @drop.prevent="handleDrop">
            <label
              for="dropzone-file"
              class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 p-6 pr-12 hover:bg-gray-100"
            >
              <div v-if="filename">
                <div class="relative mb-2 flex w-full items-center justify-end">
                  <hero-icon name="CloseCircleHeroIcon" classes="absolute w-8 h-8 text-gray-300 -right-10 hover:text-red-300"></hero-icon>
                </div>
                <div class="flex items-start justify-start gap-4">
                  <hero-icon name="DocumentIonIcon" classes="w-10 h-10 text-yellow"></hero-icon>
                  <div class="w-full break-words">
                    <p>
                      {{ $t('import_patients.csv_upload_form.file_name') }}
                      <span class="break-all text-primary">{{ _.truncate(selectedFile.name, { length: 40, omission: '...' }) }}</span>
                    </p>
                    <p>
                      {{ $t('import_patients.csv_upload_form.size') }} <span class="text-primary">{{ formatFileSize(selectedFile.size) }}</span>
                    </p>
                    <!--p>Adresse URL: {{ selectedFileUrl }}</p-->
                    <p v-if="selectedFile && selectedFile.size > 10000000" style="color: red">
                      {{ $t('import_patients.csv_upload_form.max_size_error') }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-else class="flex flex-col items-center justify-center pb-6 pt-5">
                <hero-icon name="CloudUploadOutlineHeroIcon" classes="text-textSecondary" size="large"></hero-icon>
                <p class="mb-2 text-sm text-textPrimary">
                  <span class="font-semibold">{{ $t('import_patients.csv_upload_form.click_to_add_file') }}</span>
                  {{ $t('import_patients.csv_upload_form.or_drop') }}
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">{{ $t('import_patients.csv_upload_form.file_formats') }}</p>
              </div>
              <input id="dropzone-file" ref="fileInput" type="file" class="hidden" @change="handleFileChange" @click="resetFileInput" />
            </label>
          </div>
        </div>
      </form>
    </div>
    <div class="mt-16 flex items-center justify-center">
      <a
        class="mr-2 flex w-32 items-center justify-center rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:border hover:border-primary hover:text-primary hover:no-underline"
        @click="$emit('previous-step')"
      >
        <div class="flex h-full items-center">
          <hero-icon name="ChevronLeftIonIcon" size="small"></hero-icon>
        </div>
        {{ $t('buttons.previous') }}
      </a>
      <button
        class="group flex w-32 items-center justify-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
        :class="{ 'cursor-not-allowed opacity-75': v$.$invalid }"
        :disabled="v$.$invalid || loading"
        @click="handleSubmit"
      >
        <TurningLoader v-if="loading" size="sm" class="mr-2" />
        <span v-else class="text-sm font-medium text-white group-disabled:text-textLight">{{ $t('buttons.create') }}</span>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, watch, defineEmits } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useImportPatientsStore } from '@stores/importPatients.js';
import { upload_file_and_create_patients } from '@services/importService.js';
import _ from 'lodash';

const store = useImportPatientsStore();

const { t } = useI18n();

const props = defineProps({
  beforePath: {
    type: String,
    required: true,
  },
  currentClinic: {
    type: Object,
    required: false,
    default: () => {
      return {};
    },
  },
  defaultNoOfDaysForReImport: {
    type: Number,
    required: true,
  },
  contact: {
    type: String,
    required: false,
    default: 'contact@mercidocteur.co',
  },
});

defineEmits(['close', 'previous-step']);

const useTagColumn = ref(false);
const tagColumn = ref(1);
const exitDateColumn = ref(6);
const showColumnSelection = ref(false);
const showError = ref(false);
const errorMessage = ref('');
const selectedFile = ref(null);
const selectedFileUrl = ref('');
const filename = ref(null);
const successMessage = ref('');
const showSuccess = ref(false);
const loading = ref(false);

onMounted(() => {
  initErrorMessage();
});

watch(useTagColumn, () => {
  if (useTagColumn.value === true) {
    showColumnSelection.value = true;
  } else {
    showColumnSelection.value = false;
  }
});

const rules = computed(() => {
  return {
    selectedFile: {
      required,
    },
    tagColumn: {
      minValueValue: minValue(1),
    },
    exitDateColumn: {
      minValueValue: minValue(1),
    },
  };
});

const v$ = useVuelidate(rules, { selectedFile, tagColumn, exitDateColumn });

const initErrorMessage = () => {
  errorMessage.value = t('users.standard_user_adding_message');
};

const handleCloseAlert = () => {
  showError.value = false;
  initErrorMessage();
};

const handleFileChange = event => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
    selectedFileUrl.value = URL.createObjectURL(file);
    filename.value = selectedFile.value.name;
  }
};

const handleDrop = event => {
  const file = event.dataTransfer.files[0];
  if (file) {
    selectedFile.value = file;
    selectedFileUrl.value = URL.createObjectURL(file);
    filename.value = selectedFile.value.name;
  }
};

const resetFileInput = () => {
  // Clear the file input value to allow re-uploading the same file
  selectedFile.value = null;
  selectedFileUrl.value = '';
  filename.value = null;
};

const formatFileSize = size => {
  if (size === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = parseInt(Math.floor(Math.log(size) / Math.log(k)));
  return Math.round(100 * (size / Math.pow(k, i))) / 100 + ' ' + sizes[i];
};

const handleSubmit = async () => {
  storeSettings();
  if (selectedFile.value) {
    try {
      loading.value = true;
      const response = await upload_file_and_create_patients(store.getFileParams);
      successMessage.value = response.data.message;
      showSuccess.value = true;
      setTimeout(function () {
        showSuccess.value = false;
        loading.value = false;
        handleClose();
      }, 2000);
    } catch (error) {
      errorMessage.value = error.message || t('errors.default_message');
      showError.value = true;
      setTimeout(function () {
        showError.value = false;
        errorMessage.value = null;
        loading.value = false;
      }, 3000);
    }
  } else {
    errorMessage.value = 'Vous devez sélectionner un fichier.';
    showError.value = true;
    setTimeout(function () {
      showError.value = false;
    }, 3000);
  }
};

const storeSettings = () => {
  store.updateFile({
    file: {
      useTagColumn: useTagColumn.value,
      tagColumn: tagColumn.value,
      exitDateColumn: exitDateColumn.value,
      selectedFile: selectedFile.value,
      // fileUrl: selectedFileUrl.value,
    },
  });
};

const handleClose = () => {
  store.$reset();
  window.location.href = props.beforePath;
};
</script>
<style scoped>
:root {
  --vs-border-radius: 8px;
  --vs-border-color: #f3f4f6;
  --vs-selected-bg: #e1effe;
  --vs-selected-border-color: #e1effe;
  --vs-selected-color: #4691f7;
  --vs-controls-color: #4691f7;
}
</style>
