<template>
  <div class="bg-scroll">
    <div v-if="showIaAlertMessage" id="ia-alert" class="mb-5 flex items-center justify-between rounded bg-blue-50 p-4 text-primary">
      <span class="mr-2 text-primary">{{ iaAlertMessage }}</span>
      <hero-icon name="CloseHeroIcon" classes="cursor-pointer" @click="closeIaAlertMessage"></hero-icon>
    </div>
    <ReviewCard :review="review" :type="modalType" :show-actions="false" bg-color="bg-transparent" />
    <div class="relative">
      <h3 class="mb-2 font-medium">{{ $t('reviews.add_response.modal.respond') }}</h3>
      <form class="mt-2" name="review" @submit.prevent>
        <div>
          <div class="flex w-full justify-end">
            <div v-show="showError" class="absolute top-0 rounded-full bg-red-100 px-2 py-1 text-right text-xs">
              <span class="text-xs text-danger">{{ error }}</span>
            </div>
          </div>
          <div class="mb-3 flex flex-row items-center justify-end">
            <button
              data-tooltip-target="tooltip-erase-review-response-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 py-1 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="eraseReviewResponse"
            >
              <hero-icon name="TrashHeroIcon" size="small" classes="flex items-center"></hero-icon>
            </button>
            <DefaultTooltip id="tooltip-erase-review-response-button" :message="$t('reviews.bad.tooltip_erase')" />
            <button
              v-if="iaFeatureActive"
              data-tooltip-target="tooltip-fill-with-ia-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="fillWithIa"
            >
              {{ $t('reviews.bad.ia_suggestion_button') }}
            </button>
            <div
              id="tooltip-fill-with-ia-button"
              role="tooltip"
              class="tooltip invisible absolute z-30 inline-block rounded-lg bg-primary px-3 py-2 font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700 lg:hidden"
            >
              {{ $t('reviews.bad.tooltip_suggestion_ia') }}
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <DefaultTooltip id="tooltip-fill-with-ia-button" :message="$t('reviews.bad.tooltip_suggestion_ia')" />
            <button
              v-if="iaFeatureActive"
              data-tooltip-target="tooltip-refresh-ia-button"
              class="mr-1 h-8 rounded-full bg-blue px-3 py-1 text-xs text-white hover:bg-blue-dark disabled:cursor-not-allowed disabled:bg-blue-lighter"
              :disabled="loading"
              @click="refreshIa"
            >
              <hero-icon name="SyncHeroIcon" size="small" classes="flex items-center"></hero-icon>
            </button>
            <DefaultTooltip id="tooltip-refresh-ia-button" :message="$t('reviews.bad.tooltip_refresh_ia')" />
            <div>
              <div class="dropdown text-white">
                <select
                  id="template-select"
                  v-model="selectedTemplate"
                  class="h-8 rounded-full border-none bg-blue px-3 py-1 text-xs text-white after:text-red-500 hover:bg-blue-dark focus:ring disabled:cursor-not-allowed disabled:bg-blue-lighter"
                  :disabled="loading"
                  @change="handleSelectTemplate"
                >
                  <option value="" class="appearance-none">{{ $t('reviews.select_response') }}</option>
                  <option v-for="template in templates" :key="template.id" :value="template">{{ template.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="relative mb-6">
          <div v-show="loading" id="spinner_icon" class="absolute flex h-full w-full items-center justify-center">
            <TurningLoader :message="$t('loading')" text-color="text-textLight" />
          </div>
          <textarea
            id="message"
            v-model="reviewResponse"
            rows="4"
            :disabled="loading"
            :autofocus="true"
            name="message"
            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            :placeholder="$t('reviews.write_response')"
            :class="loading ? 'opacity-20' : ''"
          >
          </textarea>
          <div class="h-1 w-full text-right">
            <button
              v-show="displaySaveTemplate"
              class="px-3 py-1 text-xs text-blue-400 hover:underline disabled:cursor-not-allowed"
              :disabled="loading"
              @click="openSaveTemplateModal('save')"
            >
              {{ $t('reviews.bad.save_as_template') }}
            </button>
            <button
              v-show="displayEditTemplate"
              class="px-3 py-1 text-xs text-blue-400 hover:underline disabled:cursor-not-allowed"
              :disabled="loading"
              @click="openSaveTemplateModal('edit')"
            >
              {{ $t('reviews.bad.edit_template') }}
            </button>
          </div>
        </div>
        <div class="mt-10 flex items-center justify-end">
          <button
            class="mr-2 rounded-lg border border-transparent bg-white px-6 py-2 text-sm font-medium text-textSecondary hover:text-primary"
            @click="closeModal"
          >
            {{ $t('buttons.cancel') }}
          </button>
          <button
            class="flex items-center rounded-lg bg-primary px-6 py-2 text-sm font-medium leading-normal text-white hover:bg-blue-dark hover:drop-shadow-md disabled:cursor-not-allowed disabled:bg-bgLight disabled:text-textLight"
            :disabled="disableSubmitButton"
            @click="showConfirmationModal = true"
          >
            <div class="mr-2">{{ $t('reviews.bad.send_reply') }}</div>
            <hero-icon name="PaperPlaneIonIcon" size="small"></hero-icon>
          </button>
        </div>
        <Modal
          v-model="showSaveTemplateModal"
          :title="selectedTemplate ? $t('reviews.bad.edit_template') : $t('reviews.bad.save_as_template')"
          wrapper-class="modal-wrapper"
          modal-class="modal modal-lg"
        >
          <ReviewTemplateSaveModal
            :templates-size="templatesSize"
            :review-response="reviewResponse"
            :template="selectedTemplate"
            :templates-names="templatesNames"
            :type="modalType"
            :clinic-id="clinicId"
            @close="showSaveTemplateModal = false"
          />
        </Modal>
        <Modal
          v-model="showConfirmationModal"
          :title="$t('response_confirmation_modal.title')"
          wrapper-class="modal-wrapper"
          modal-class="modal modal-md"
        >
          <ReviewResponseConfirmationModal
            :review-response="reviewResponse"
            :response-template-name="reviewResponseTemplateName"
            :review-id="review.id"
            :clinic-id="clinicId"
            :type="modalType"
            @close="showConfirmationModal = false"
          />
        </Modal>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import ReviewResponseConfirmationModal from '@components/shared/modals/ReviewResponseConfirmationModal.vue';
import ReviewTemplateSaveModal from '@components/shared/modals/ReviewTemplateSaveModal.vue';
import { useReviewsStore } from '@stores/reviews.js';
const store = useReviewsStore();

const emit = defineEmits(['close']);

const props = defineProps({
  // @example:
  // { id:, author:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply:}
  review: {
    type: Object,
    required: true,
  },
  clinicId: {
    type: Number,
    required: true,
  },
  iaAlertMessage: {
    type: String,
    required: false,
    default: '',
  },
  iaFeatureActive: {
    type: Boolean,
    required: false,
    default: false,
  },
  templates: {
    type: Array,
    required: true,
  },
  modalType: {
    type: String, // 'google' or 'review' or 'surveyReview'
    default: 'google',
  },
});

// @example: { id:, author:, comment:, avatarURl:, totalRatingNb:, rating:, reviewDisplayDate:, replyDisplayDate:, reply:}
const reviewResponse = ref('');
const reviewResponseTemplateName = ref('');
const loading = ref(false);
const error = ref('');
const showError = ref(false);
const selectedTemplate = ref('');
const showSaveTemplateModal = ref(false);
const showConfirmationModal = ref(false);

const templatesNames = computed(() => {
  return props.templates.map(template => template.name);
});

const templatesSize = computed(() => {
  return props.templates.length;
});

const showIaAlertMessage = computed(() => {
  return props.iaAlertMessage != '' && store.showIaAlertMessage;
});

const disableSubmitButton = computed(() => {
  return reviewResponse.value === undefined || reviewResponse.value === null || reviewResponse.value.trim() === '' || loading.value;
});

const displaySaveTemplate = computed(() => {
  return reviewResponseExist.value && differentResponseOnSelectedTemplate.value;
});

const displayEditTemplate = computed(() => {
  return reviewResponseExist.value && selectedTemplate.value !== '';
});

const reviewResponseExist = computed(() => {
  return reviewResponse.value && reviewResponse.value.length > 1;
});

const differentResponseOnSelectedTemplate = computed(() => {
  if (selectedTemplate.value == '') {
    return true;
  } else {
    return selectedTemplate.value.content !== reviewResponse.value;
  }
});

watch(reviewResponse, newReviewResponse => {
  if (newReviewResponse === '') {
    selectedTemplate.value = '';
    reviewResponseTemplateName.value = '';
  }
  switch (props.modalType) {
    case 'google':
      store.updateGoogleReviewResponseById({
        googleReviewId: props.review.id,
        message: newReviewResponse,
        responseTemplateName: reviewResponseTemplateName.value,
      });
      break;
    case 'review':
      store.updateReviewResponseById({
        reviewId: props.review.id,
        message: newReviewResponse,
        responseTemplateName: reviewResponseTemplateName.value,
      });
      break;
    case 'surveyReview':
      store.updateSurveyReviewResponseById({
        surveyReviewId: props.review.id,
        message: newReviewResponse,
        responseTemplateName: reviewResponseTemplateName.value,
      });
      break;
  }
});

onMounted(() => {
  let message = undefined;
  let responseTemplateName = undefined;
  if (props.review.reply) {
    message = props.review.reply;
  } else {
    let review = {};
    switch (props.modalType) {
      case 'google':
        review = store.getGoogleReviewResponseById(props.review.id);
        message = review && review.message;
        responseTemplateName = review && review.responseTemplateName;
        break;
      case 'review':
        review = store.getReviewResponseById(props.review.id);
        message = review && review.message;
        responseTemplateName = review && review.responseTemplateName;
        break;
      case 'surveyReview':
        review = store.getSurveyReviewResponseById(props.review.id);
        message = review && review.message;
        responseTemplateName = review && review.responseTemplateName;
        break;
    }
  }
  if (message) {
    reviewResponse.value = message;
    reviewResponseTemplateName.value = responseTemplateName || '';
  } else {
    reviewResponse.value = '';
    reviewResponseTemplateName.value = '';
  }
});

const handleSelectTemplate = () => {
  if (selectedTemplate.value !== '' || selectedTemplate.value !== null) {
    reviewResponse.value = selectedTemplate.value.content;
    reviewResponseTemplateName.value = selectedTemplate.value.name;
  } else {
    reviewResponse.value = '';
    reviewResponseTemplateName.value = '';
  }
};

const eraseReviewResponse = () => {
  reviewResponse.value = '';
  selectedTemplate.value = '';
  reviewResponseTemplateName.value = '';
};

const fillWithIa = () => {
  let suggestion;
  switch (props.modalType) {
    case 'google':
      suggestion = store.getGoogleIaSuggestionById(props.review.id);
      break;
    case 'review':
      suggestion = store.getIaSuggestionById(props.review.id);
      break;
  }
  if (suggestion === null || suggestion.trim() === '') {
    refreshIa();
  } else {
    reviewResponse.value = suggestion;
  }
};

const refreshIa = async () => {
  loading.value = true;
  try {
    let res;
    switch (props.modalType) {
      case 'google':
        res = await store.fetchAndStoreGoogleSuggestionIa({ googleReviewId: props.review.id, clinicId: props.clinicId });
        break;
      case 'review':
        res = await store.fetchAndStoreSuggestionIa({ reviewId: props.review.id });
        break;
      default:
        res = { suggestion: '', error: 'Permission refusée' };
    }
    loading.value = false;
    if (res.error) {
      handleErrorInFetchingIa(res.error);
    } else {
      reviewResponse.value = res.suggestion;
    }
  } catch (error) {
    loading.value = false;
    handleErrorInFetchingIa(error.message);
  }
};

const handleErrorInFetchingIa = newError => {
  error.value = newError;
  showError.value = true;
  setTimeout(() => (showError.value = false), 4000);
};

const closeIaAlertMessage = () => {
  store.hideIaAlertMessage();
};

const closeModal = () => {
  emit('close');
};

const openSaveTemplateModal = mode => {
  if (mode === 'save') {
    selectedTemplate.value = '';
  }
  showSaveTemplateModal.value = true;
};
</script>
<style scoped>
.dropdown select:not([size]) {
  background-image: url("data:image/svg+xml,%3csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 10 6'%3e %3cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 1 4 4 4-4'/%3e %3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.75em 0.75em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
</style>
