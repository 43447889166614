import '@hotwired/turbo-rails';
import TurbolinksAdapter from 'vue-turbolinks';
import * as Sentry from '@sentry/vue';
import { createI18n } from 'vue-i18n';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
// import lib components
import { Modal } from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';
import vSelect from 'vue-select';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import { initFlowbite } from 'flowbite';
import 'flowbite/dist/datepicker.turbo.js';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import VueApexCharts from 'vue3-apexcharts';
import RadialProgress from 'vue3-radial-progress';

// import app components
import DataWrapper from '@components/shared/wrapper/DataWrapper.vue';
import AutomaticGoogleReviewReponseTemplatesList from '@components/automatic_google_review_response/AutomaticGoogleReviewReponseTemplatesList.vue';
import SimpleToast from '@components/shared/toasts/SimpleToast.vue';
import ClinicsNavigationDropdownGroup from '@components/navbar/ClinicsNavigationDropdownGroup.vue';
import SideBar from '@components/sidebar/SideBar.vue';
import TabsHandler from '@components/shared/tabs/TabsHandler.vue';
import DefaultChart from '@components/shared/charts/DefaultChart.vue';
import BarChart from '@components/shared/charts/BarChart.vue';
import ColumnChart from '@components/shared/charts/ColumnChart.vue';
import GaugeChart from '@components/shared/charts/GaugeChart.vue';
import DonutChart from '@components/shared/charts/DonutChart.vue';
import RadialProgressBar from '@components/shared/progress/RadialProgressBar.vue';
import MultiStepper from '@components/shared/progress/MultiStepper.vue';
import ImportPatientsButtonGroup from '@components/import_patients/ImportPatientsButtonGroup.vue';
import ImportFlow from '@components/import_patients/ImportFlow.vue';
import ExtensionSettingForm from '@components/import_patients/forms/ExtensionSettingForm.vue';
import UserNotificationsForm from '@components/shared/forms/UserNotificationsForm.vue';
import UserAuthenticationTokenForm from '@components/shared/forms/UserAuthenticationTokenForm.vue';
import ReviewFilterForm from '@components/shared/forms/ReviewFilterForm.vue';
import PatientFilterForm from '@components/shared/forms/PatientFilterForm.vue';
import SurveyReviewFilterForm from '@components/shared/forms/SurveyReviewFilterForm.vue';
import PatientEditForm from '@components/shared/forms/PatientEditForm.vue';
import PatientNewForm from '@components/shared/forms/PatientNewForm.vue';
import ReviewCard from '@components/shared/cards/ReviewCard.vue';
import SurveyCard from '@components/shared/cards/SurveyCard.vue';
import GoogleLocationCard from '@components/shared/cards/GoogleLocationCard.vue';
import TurningLoader from '@components/shared/loaders/TurningLoader.vue';
import ExportReviewsGroup from '@components/export/ExportReviewsGroup.vue';
import ExportSurveyReviewsGroup from '@components/export/ExportSurveyReviewsGroup.vue';
import DefaultTooltip from '@components/shared/tooltips/DefaultTooltip.vue';
import SimpleAlert from '@components/shared/alerts/SimpleAlert.vue';
import ReviewCardSkeleton from '@components/shared/skeletons/ReviewCardSkeleton.vue';
import StatCardSkeleton from '@components/shared/skeletons/StatCardSkeleton.vue';
import ReviewTableSkeleton from '@components/shared/skeletons/ReviewTableSkeleton.vue';
import FullscreenButton from '@components/shared/buttons/FullscreenButton';
import AddUserButtonAndModal from '@components/shared/modals/AddUserButtonAndModal.vue';
import UserLogsButtonAndModal from '@components/shared/modals/UserLogsButtonAndModal.vue';
import UserCsvsButtonAndModal from '@components/shared/modals/UserCsvsButtonAndModal.vue';
import HeroIcon from '@components/shared/icons/HeroIcon.vue';
import '@stylesheets/stats_tooltip.css';

// import css
// const instance = import.meta.env.INSTANCE_VERSION;
// await import(`../themes/${instance}.css?inline`);

// polyfills
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';

// Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
// or
// import.meta.globEager('../channels/**/*_channel.js')

// uploading directly from the client to the cloud
// https://guides.rubyonrails.org/v7.0/active_storage_overview.html#usage
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

document.addEventListener('turbo:load', () => {
  console.log('Turbo!');
  const mainApp = createApp({});

  Sentry.init({
    app: mainApp,
    dsn: import.meta.env.SENTRY_DSN,
    tracesSampleRate: import.meta.env.SENTRY_TRACE_SAMPLE_RATE || 0.001,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: import.meta.env.SENTRY_REPLAYS_SESSION_SAMPLE_RATE || 0.01,
    replaysOnErrorSampleRate: import.meta.env.SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || 0.5,
  });

  const pinia = createPinia();
  const i18n = createI18n({
    legacy: false,
    messages: window.I18n, // set locale messages
    locale: 'fr',
    fallbackLocale: 'en',
  });

  mainApp
    .use(TurbolinksAdapter)
    .use(pinia)
    .use(i18n)
    .use(VueTelInput, {
      defaultCountry: 'fr',
      preferredCountries: ['fr'],
    })
    .use(VueApexCharts)
    .use(VueTailwindDatepicker)
    .component('VSelect', vSelect)
    .component('HeroIcon', HeroIcon)
    .component('Modal', Modal)
    .component('DataWrapper', DataWrapper)
    .component('AutomaticGoogleReviewReponseTemplatesList', AutomaticGoogleReviewReponseTemplatesList)
    .component('SimpleToast', SimpleToast)
    .component('ClinicsNavigationDropdownGroup', ClinicsNavigationDropdownGroup)
    .component('SideBar', SideBar)
    .component('TabsHandler', TabsHandler)
    .component('DefaultChart', DefaultChart)
    .component('BarChart', BarChart)
    .component('ColumnChart', ColumnChart)
    .component('GaugeChart', GaugeChart)
    .component('DonutChart', DonutChart)
    .component('RadialProgress', RadialProgress)
    .component('MultiStepper', MultiStepper)
    .component('RadialProgressBar', RadialProgressBar)
    .component('ImportPatientsButtonGroup', ImportPatientsButtonGroup)
    .component('ImportFlow', ImportFlow)
    .component('ExtensionSettingForm', ExtensionSettingForm)
    .component('ExportReviewsGroup', ExportReviewsGroup)
    .component('ExportSurveyReviewsGroup', ExportSurveyReviewsGroup)
    .component('ReviewCard', ReviewCard)
    .component('SurveyCard', SurveyCard)
    .component('GoogleLocationCard', GoogleLocationCard)
    .component('TurningLoader', TurningLoader)
    .component('DefaultTooltip', DefaultTooltip)
    .component('ReviewCardSkeleton', ReviewCardSkeleton)
    .component('StatCardSkeleton', StatCardSkeleton)
    .component('ReviewTableSkeleton', ReviewTableSkeleton)
    .component('SimpleAlert', SimpleAlert)
    .component('AddUserButtonAndModal', AddUserButtonAndModal)
    .component('UserLogsButtonAndModal', UserLogsButtonAndModal)
    .component('UserCsvsButtonAndModal', UserCsvsButtonAndModal)
    .component('PatientFilterForm', PatientFilterForm)
    .component('SurveyReviewFilterForm', SurveyReviewFilterForm)
    .component('PatientEditForm', PatientEditForm)
    .component('PatientNewForm', PatientNewForm)
    .component('ReviewFilterForm', ReviewFilterForm)
    .component('UserNotificationsForm', UserNotificationsForm)
    .component('UserAuthenticationTokenForm', UserAuthenticationTokenForm)
    .component('FullscreenButton', FullscreenButton);

  mainApp.config.errorHandler = err => {
    /* handle error */
    console.log(err);
  };

  mainApp.mount('#app');
  initFlowbite();
  if (typeof initFreshChat === 'function') {
    initFreshChat();
  }
  // To avoid Turbo reload links for anchor
  // document.querySelectorAll('a[href^="#"]').forEach(function (el) {
  //   el.setAttribute('data-turbo', false);
  // });
});
document.addEventListener('turbo:before-visit', function () {
  if (window.fcWidget && window.fcWidget.isInitialized()) {
    window.fcWidget.destroy(); // remove freshchat because of turbo o reinitialize it on navigation
  }
});
